import React from "react"
import {Layout, Hero} from "components/layout"
import SEO from "components/seo"
import { SignupButton, Row, Typography,  Container, Section, SectionTitle, FeatureGrid, Feature } from "components/ui"
import Image from "components/image"

const ApiAndBackupAutomation = () => (
  <Layout>
		<SEO refKey="api-automation" />
    <Section color="alt">
      <Container>
        <Row>
          <div className="lg:grid grid-cols-12 gap-20">
            <div className="lg:col-span-6">
              <h1 className="font-heading tracking-tight text-5xl md:text-6xl md:leading-tight font-black">
                Automate all backup stages, using our API and triggers
              </h1>
              <Typography tag="p" className="mt-2">
                Get your backups integrated right into your application using our API, webhooks or backup triggers.
                You can programmatically, trigger a backup or trigger actions when a backup is completed.
                <br/>
                <br/>
                For critical application, you can easily take a backup of your project right before a new deployment is made,
                and make sure you always have a recovery plan in place.
              </Typography>
              <SignupButton className="mt-12" urlAtrr={{ sb_source: "website", sb_term: "features" }} noSubtitle text="Try Simple Backups API now"/>
            </div>
            <div className="hidden lg:col-span-6 lg:block">
              <Image filename="simplebackups-api.png" alt="Server and database backups automation" style={{maxWidth: '484px'}}/>
            </div>
          </div>
        </Row>
      </Container>
    </Section>
    <Section>
      <Container>
        <Row>
          <div className="col-md-12">
            <SectionTitle
              title={(<>Integrate backups into your workflow</>)}
              subTitle={<> We are constantly improving the backup process performance to provide the best backup speed for the
              least resource usage possible.</>}
              tag="h2"
              align="center"
            />
            <FeatureGrid cols="3" className="mt-8">
              <Feature
                icon={<i className="fa fa-ball-pile" />}
                title="Developer API"
                content="Save storage, save time, save money. For large project that's how backup should be done."
                boxed
              />
              <Feature
                icon={<i className="fa fa-unlink" />}
                title="Webhooks"
                content="We'll send you a request whenever a backup is completed. Can be easily integrated into your workflow."
                boxed
              />
              <Feature
                  icon={<i className="far fa-signal-stream" />}
                  title="Backup Trigger URL"
                  content="A simple HTTP request to this URL and your backup will be taken."
                  boxed />
            </FeatureGrid>
          </div>
        </Row>
      </Container>
    </Section>
  </Layout>
)

export const frontmatter = {
  pageMetaData: {
    refKey: "api-automation",
    title: "Backup automation and developer API for integrated database & file backups.",
    description: "Automate backup triggers and integrate backup in your deployment process using our API, Webhooks and WebTriggers.",
    author: "simplebackups.com"
  }
}

export default ApiAndBackupAutomation
